var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [_vm.data.block, { "mb-2": _vm.layout !== "template7" }],
      attrs: { id: "banner-type6" },
    },
    [
      _c(
        "swiper",
        {
          ref: "slider",
          staticClass: "swiper mx-6",
          attrs: { options: _vm.swiperOption },
        },
        _vm._l(_vm.banners, function (banner, index) {
          return _c("swiper-slide", { key: banner._id + index }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: banner.mobile_image,
                    expression: "banner.mobile_image",
                  },
                ],
                staticClass: "border-image-clip-path p-relative",
              },
              [
                _c("v-img", {
                  staticClass: "slider-img",
                  attrs: {
                    width: "100%",
                    src: _vm.baseImgUrl + banner.mobile_image || _vm.image,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.clickBanner(
                        banner.mobile_hyperlink,
                        _vm.$router
                      )
                    },
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass:
                      "p-absolute text--white right-0 bottom-0 thai-bg px-5 py-1 rounded-tl font-weight-bold",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("global.more")) + " ")]
                ),
              ],
              1
            ),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }